/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@import '~animate.css/animate.min.css';
@import "~quill/dist/quill.snow.css";


.ql-toolbar.ql-snow {
    border: 1px solid #ccc;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
  }
  .ql-container {
    background: whitesmoke !important;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }
  .ql-fullscreen:after {
    content: 'M';
    color: red;
  }



ion-content {
    --background: linear-gradient(to top, #dfe9f3 0%, white 100%);
}
ion-content.white { --background: white; }
ion-content.offWhite {
    background-color: #f1f3f6;
    /*linear-gradient(330deg, #f0f0f0, white)*/
}
ion-content.blue {
    --background: linear-gradient(259deg, deepskyblue, cornflowerblue)
}
ion-content.grad1 {
    --background: linear-gradient(-30deg, rgba(2,0,36,1) 0%, rgba(34,82,168,1) 30%, rgba(28,186,218,1) 74%, rgba(0,202,255,1) 100%);
}
ion-content.grey, ion-content.gray,
.bg-grey, .bg-gray {
    --background: #e2ebf4;
    background: #e2ebf4;
}
ion-content.darkblue, ion-content.darkblue,
.bg-darkblue {
    --background: #141e30;
    background: #141e30;
}

ion-content.grad2,
.bg-grad2 {
    --background: linear-gradient(to bottom, #111122, #141E30, #0f0c29);
    background: linear-gradient(to bottom, #111122, #141E30, #0f0c29);
}
ion-content.grad3,
.bg-grad3 {
    --background: linear-gradient(to bottom, #24243e, #141E30, #0f0c29);
    background: linear-gradient(to bottom, #24243e, #141E30, #0f0c29);
}

ion-list.trans,
ion-item.trans {
    --background: transparent;
    background: transparent;
}

ion-list.small,
ion-item.small {
    font-size: 11px;
}


ion-input.bb {
    border-bottom: solid 1px rgba(255,255,255, 0.5);
}

ion-input.br {
    --border-radius: 15px;
    --border: solid 1px rgba(0,0,0, 0.2);
    border-radius: 15px;
    border: solid 1px rgba(0,0,0, 0.2);
}

ion-input.custom {
    --border-radius: 12px;
    --border: solid 1px rgba(0,0,0, 0.2);
    border-radius: 12px;
    border: solid 1px rgba(0,0,0, 0.2);

    --background: whitesmoke;
    background: whitesmoke;

    --padding-start: 15px;
    color: black !important;
    font-size: 12px;
}

ion-select.custom {
    --border-radius: 12px;
    --border: solid 1px rgba(0,0,0, 0.2);
    border-radius: 12px;
    border: solid 1px rgba(0,0,0, 0.2);

    --background: whitesmoke;
    background: whitesmoke;

    --padding-start: 15px;
    color: black !important;
    font-size: 12px;
}


ion-button.br {
    --border-radius: 15px;
    --border: solid 1px rgba(0,0,0, 0.2);
}

ion-button.br2 {
    --border-radius: 30px;
    --border: solid 1px rgba(0,0,0, 0.2);
}

ion-button.rad {
    --border-radius: 25px;
}

ion-input.riddle-answer {
    border: dashed 2px rgba(0,0,0, 0.15);
    border-radius: 6px;
    background: white;

    /* padding: 2px 0px !important; */
    padding: 2px 35px 0px 10px !important;
    /* box-shadow: 0px 0px 0px 8px #75d3fe; */
    box-shadow: 0px 0px 0px 8px #75d3fe,
                0px 0px 0px 13px rgb(105 106 105 / 16%),
                12px 12px 25px #747e88,
                -12px -12px 25px #c5f1ff;
    /* border: 4px solid #51a3c7; */
    border: 4px dashed #51a3c7;
    background: chartreuse;
    color: #333;
    text-align: left;



    border-radius: 3px;
    padding: 0px !important;
    padding: 0 5px 0 13px !important;
    box-shadow: 0px 0px 0px 6px #75d3fe, 0px 0px 0px 8px rgb(103 115 124), 12px 12px 25px #747e88, -12px -12px 25px #c5f1ff;
    border: 2px dashed #51a3c7;
    background: chartreuse;
    color: #333;
    text-align: center;
    font-size: 13px;
    height: 40px;


    &:after {
        content: "";
        position: absolute;
        top: -13px;
        left: -13px;
        border-radius: 15px;
        width: calc(100% + 26px);
        height: calc(100% + 26px);
        background: repeating-linear-gradient(
            0deg,
            rgba(black, 0.15),
            rgba(black, 0.15) 1px,
            transparent 1px,
            transparent 2px
        );

        top: -8px;
        left: -8px;
        border-radius: 7px;
        width: calc(100% + 16px);
        height: calc(100% + 16px);
    }


}
/* 
::-webkit-input-placeholder {
    text-align: center !important;
}
 */

ion-spinner.x2 { transform: scale(2); }
ion-spinner.x3 { transform: scale(3); }
ion-spinner.x4 { transform: scale(4); }

ion-modal.custom-modal::part(content) {    
    /* --ion-background-color:#f12000; */
    --border-radius: 8px;
    --max-height: 85%;
    --max-width: 95%;
    --width: 95%;
    --height: 70%;
    /* --border-style: dotted; */
    /* --border-color: #428cff; */
    /* border-width: 5px; */
    top: 10%;
 }






:focus { outline: none; } /* REMOVE CLICK-OUTLINE */


*:focus { -webkit-tap-highlight-color: transparent; }
/* Remove outline for non-keyboard :focus */
*:focus:not(.focus-visible) {
    outline: none;
}
/* Optional: Customize .focus-visible */
.focus-visible {
    outline-color: lightgreen;
}
#monitor *, #monitor *:focus,
#monitor > *, #monitor > *:focus {
    outline: none !important;
    -webkit-tap-highlight-color: transparent !important;
    border: none !important;
}


.fixed { position: fixed; z-index:10; }
.inline { display: inline; }
.inline-block { display: inline-block; }
.block { display: block; }

.bg {
    margin: 0;
    padding: 0;
    position: fixed;
    z-index: 0;
    bottom: -130px;
    left: 7px;
    transform: rotate(341deg) rotateX(129deg) translateZ(1em);
    transform: rotate(354deg) rotateX(27deg) translateZ(1em);
    opacity: 0.04;
    height: 100%;
    width: 100%;
/* 
    bottom: -100px;
    left: -60px;
    transform: rotate(135deg) rotateX(90deg) translateZ(1em);
*/
}
.bg > img {
    max-width: unset;
    max-height: 550px;
}
.oldfont { font-family: 'QuicksandRegular' !important; }
.cryptedfont { font-family: 'QuicksandRegular' !important; }
.gamefont { font-family: 'QuicksandRegular' !important; }
.gamefont2 { font-family: 'QuicksandRegular' !important; }
.monofont { font-family: 'QuicksandRegular' !important; }
.monofont2 { font-family: 'QuicksandRegular' !important; }
.hasGlobalHeader { margin-top: 100px; }

.shad { box-shadow: 2px 2px 6px rgba(12, 12, 12, 0.13); }

.pointer { cursor: pointer; }

.h-100px { height: 100px; }

.wf-0 { width: 100%; }
.wf-10 { width: calc(100% - 10px); }
.wf-20 { width: calc(100% - 20px); }
.wf-30 { width: calc(100% - 30px); }
.wf-40 { width: calc(100% - 40px); }
.wf-50 { width: calc(100% - 50px); }
.wf-60 { width: calc(100% - 60px); }
.wf-70 { width: calc(100% - 70px); }
.wf-80 { width: calc(100% - 80px); }
.wf-90 { width: calc(100% - 90px); }
.wf-100 { width: calc(100% - 100px); }

.t-ls-1 { letter-spacing: 1px; }
.t-ls-2 { letter-spacing: 2px; }
.t-ls-3 { letter-spacing: 3px; }
.t-ls-4 { letter-spacing: 4px; }
.t-ls-5 { letter-spacing: 5px; }
.t-center { text-align: center; }
.t-left { text-align: left; }
.t-right { text-align: right; }

.m-center { margin-left: auto; margin-right: auto; }
.m-0 { margin: 0; }

.ml-0 { margin-left: 0; }
.ml-5 { margin-left: 5px; }
.ml-10 { margin-left: 10px; }
.ml-15 { margin-left: 15px; }
.ml-20 { margin-left: 20px; }
.ml-25 { margin-left: 25px; }
.ml-30 { margin-left: 30px; }


.mr-0 { margin-right: 0; }
.mr-5 { margin-right: 5px; }
.mr-10 { margin-right: 10px; }
.mr-15 { margin-right: 15px; }
.mr-20 { margin-right: 20px; }
.mr-25 { margin-right: 25px; }
.mr-30 { margin-right: 30px; }

.mt-0 { margin-top: 0; }
.mt-5 { margin-top: 5px; }
.mt-10 { margin-top: 10px; }
.mt-15 { margin-top: 15px; }
.mt-20 { margin-top: 20px; }
.mt-25 { margin-top: 25px; }
.mt-30 { margin-top: 30px; }
.mt-40 { margin-top: 40px; }
.mt-50 { margin-top: 50px; }
.mt-60 { margin-top: 60px; }
.mt-70 { margin-top: 70px; }
.mt-75 { margin-top: 75px; }
.mt-80 { margin-top: 80px; }
.mt-90 { margin-top: 90px; }
.mt-100 { margin-top: 100px; }
.mt-110 { margin-top: 110px; }
.mt-120 { margin-top: 120px; }
.mt-130 { margin-top: 130px; }
.mt-140 { margin-top: 140px; }
.mt-150 { margin-top: 150px; }
.mt-160 { margin-top: 160px; }
.mt-170 { margin-top: 170px; }
.mt-180 { margin-top: 180px; }
.mt-190 { margin-top: 190px; }
.mt-200 { margin-top: 200px; }
.mt-210 { margin-top: 210px; }
.mt-220 { margin-top: 220px; }
.mt-230 { margin-top: 230px; }
.mt-240 { margin-top: 240px; }
.mt-250 { margin-top: 250px; }
.mt-260 { margin-top: 260px; }
.mt-270 { margin-top: 270px; }
.mt-280 { margin-top: 280px; }
.mt-290 { margin-top: 290px; }
.mt-300 { margin-top: 300px; }
.mt-310 { margin-top: 310px; }
.mt-320 { margin-top: 320px; }
.mt-330 { margin-top: 330px; }
.mt-340 { margin-top: 340px; }
.mt-350 { margin-top: 350px; }

.mb-0 { margin-bottom: 0; }
.mb-5 { margin-bottom: 5px; }
.mb-10 { margin-bottom: 10px; }
.mb-15 { margin-bottom: 15px; }
.mb-20 { margin-bottom: 20px; }
.mb-30 { margin-bottom: 30px; }
.mb-40 { margin-bottom: 40px; }
.mb-50 { margin-bottom: 50px; }
.mb-60 { margin-bottom: 60px; }
.mb-70 { margin-bottom: 70px; }
.mb-80 { margin-bottom: 80px; }
.mb-90 { margin-bottom: 90px; }
.mb-100 { margin-bottom: 100px; }




.pt-0 { padding-top: 0; }
.pt-5 { padding-top: 5px; }
.pt-10 { padding-top: 10px; }
.pt-15 { padding-top: 15px; }
.pt-20 { padding-top: 20px; }
.pt-25 { padding-top: 25px; }
.pt-30 { padding-top: 30px; }
.pt-40 { padding-top: 40px; }
.pt-50 { padding-top: 50px; }
.pt-60 { padding-top: 60px; }
.pt-70 { padding-top: 70px; }
.pt-75 { padding-top: 75px; }
.pt-80 { padding-top: 80px; }
.pt-90 { padding-top: 90px; }
.pt-100 { padding-top: 100px; }


.pb-0 { padding-bottom: 0; }
.pb-5 { padding-bottom: 5px; }
.pb-10 { padding-bottom: 10px; }
.pb-15 { padding-bottom: 15px; }
.pb-20 { padding-bottom: 20px; }
.pb-25 { padding-bottom: 25px; }
.pb-30 { padding-bottom: 30px; }
.pb-40 { padding-bottom: 40px; }
.pb-50 { padding-bottom: 50px; }
.pb-60 { padding-bottom: 60px; }
.pb-70 { padding-bottom: 70px; }
.pb-75 { padding-bottom: 75px; }
.pb-80 { padding-bottom: 80px; }
.pb-90 { padding-bottom: 90px; }
.pb-100 { padding-bottom: 100px; }


.left30 { left: 30px }


.backbtn_wrapper {
    top: 50px;/* orig 40 */
    left: 20px;/* orig 15 */
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 4px 0 0 3px;
    background: #fff;
    border: 0px solid white;
    border-radius: 50px;
    box-shadow: 2px 2px 6px rgba(12, 12, 12, 0.13);
    text-align: left;
    color: black;
}
.backbtn_wrapper.landscape {
    top: 20px !important;
  }
@media(max-device-height : 667px) {
    .backbtn_wrapper {
        top: 30px !important;
    }
}

#debug_fab_button {
    top: 5px;
    opacity: .6;
    left: 5px;
}





.page-centered-element {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-bottom-element {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
}





.ion-color-special {
    --ion-color-base: var(--ion-color-special);
    --ion-color-base-rgb: var(--ion-color-special-rgb);
    --ion-color-contrast: var(--ion-color-special-contrast);
    --ion-color-contrast-rgb: var(--ion-color-special-contrast-rgb);
    --ion-color-shade: var(--ion-color-special-shade);
    --ion-color-tint: var(--ion-color-special-tint);
  }
  .ion-color-special2 {
    --ion-color-base: var(--ion-color-special2);
    --ion-color-base-rgb: var(--ion-color-special2-rgb);
    --ion-color-contrast: var(--ion-color-special2-contrast);
    --ion-color-contrast-rgb: var(--ion-color-special2-contrast-rgb);
    --ion-color-shade: var(--ion-color-special2-shade);
    --ion-color-tint: var(--ion-color-special2-tint);
  }
  .ion-color-special3 {
    --ion-color-base: var(--ion-color-special3);
    --ion-color-base-rgb: var(--ion-color-special3-rgb);
    --ion-color-contrast: var(--ion-color-special3-contrast);
    --ion-color-contrast-rgb: var(--ion-color-special3-contrast-rgb);
    --ion-color-shade: var(--ion-color-special3-shade);
    --ion-color-tint: var(--ion-color-special3-tint);
  }


  .custom-modal .modal-wrapper {
    /* --ion-background-color:#f12000; */
    --border-radius: 8px;
    --max-height: 85%;
    --max-width: 90%;
    --width: 90%;
    --height: 70%;
    /* --border-style: dotted; */
    /* --border-color: #428cff; */
    /* border-width: 5px; */
    top: 10%;
}
.custom-modal .modal-shadow {
    top: 0;
    --max-height: 0px;
    --max-width: 0px;
    --width: 0px;
    --height: 0px;
}

.game-modal .modal-wrapper {
    /* --ion-background-color:#f12000; */
    --border-radius: 38px;
    --max-height: 85%;
    --max-width: 90%;
    --width: 90%;
    --height: 420px;
    /* --border-style: dotted; */
    /* --border-color: #428cff; */
    /* border-width: 5px; */
    top: 10%;
}
.game-modal .modal-shadow {
    top: 0;
    --max-height: 0px;
    --max-width: 0px;
    --width: 0px;
    --height: 0px;
    opacity: 0 !important;
    display: none !important;
}

.modal-profile .modal-wrapper {
    /* background:#f12000 !important; */
    --border-radius: 8px;
    --max-height: 85%;
    --max-width: 90%;
    --width: 90%;
    --height: 70%;
    /* --border-style: dotted; */
    /* --border-color: #428cff; */
    /* border-width: 5px; */
    top: 10%;
}
.modal-profile .modal-shadow {
    top: 0;
    --max-height: 0px;
    --max-width: 0px;
    --width: 0px;
    --height: 0px;
}


/* Animate TextColor */
@keyframes color-change {
    0% { color: greenyellow; }
    50% { color: blue; }
    100% { color: red; }
  }


/* ----------------------------------------------
 * Generated by Animista on 2021-2-14 15:18:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-rotate-hor-b-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes slide-rotate-hor-b-fwd {
    0% {
      -webkit-transform: translateY(0) translateZ(0) rotateX(0deg);
              transform: translateY(0) translateZ(0) rotateX(0deg);
      -webkit-transform-origin: top center;
              transform-origin: top center;
    }
    100% {
      -webkit-transform: translateY(150px) translateZ(130px) rotateX(90deg);
              transform: translateY(150px) translateZ(130px) rotateX(90deg);
      -webkit-transform-origin: top center;
              transform-origin: top center;
    }
  }
  @keyframes slide-rotate-hor-b-fwd {
    0% {
      -webkit-transform: translateY(0) translateZ(0) rotateX(0deg);
              transform: translateY(0) translateZ(0) rotateX(0deg);
      -webkit-transform-origin: top center;
              transform-origin: top center;
    }
    100% {
      -webkit-transform: translateY(150px) translateZ(130px) rotateX(90deg);
              transform: translateY(150px) translateZ(130px) rotateX(90deg);
      -webkit-transform-origin: top center;
              transform-origin: top center;
    }
  }
  
  @-webkit-keyframes waver { 100% { -webkit-transform: rotateY(120deg); } }
  @keyframes waver { 100% { -webkit-transform: rotateY(120deg); transform:rotate(120deg); } }

  @-webkit-keyframes rotate { 100% { -webkit-transform: rotate(360deg); } }
  @keyframes rotate { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.slide-rotate-hor-b-fwd {
	-webkit-animation: slide-rotate-hor-b-fwd 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: slide-rotate-hor-b-fwd 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}


.roundt path {
    fill: transparent;
  }
  
.roundt text {
    fill: #FF9800;
  }




  













.btn_game2 {
    background: chartreuse;
    width: min-content;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 5px #75d3fe;
    border: 4px solid #51a3c7;
    font-size: 16px;
    color: #333;
    padding: 6px 14px;
    position: relative;
    display: inline-block;
}
.btn_game2.hidden {
    display: none;
}
.btn_game2.alt {
    background: #112572;
    box-shadow: 0px 0px 0px 5px #7fff00;
    border: 4px solid #4b7e81;
    color: chartreuse;
}
.btn_game2::after {
  content: "";
  position: absolute;
  top: -9px;
  left: -9px;
  width: calc(100% + 18px);
  height: calc(100% + 18px);
  border-radius: 13px;
  background: repeating-linear-gradient(
    0deg,
    rgba(black, 0.15),
    rgba(black, 0.15) 1px,
    transparent 1px,
    transparent 2px
  );
}



#log {
    display:none;
    z-index:9;
    font-family:Courier;
    position:absolute;
    bottom: 0;
    height: 200px;
    width: 100%;
    padding:10px;
    font-size:10px;
    background: whitesmoke;
    color:rgba(0,0,0,0.85)
}
#log.fullscreen {
    bottom: unset;
    top: 0;
    height: 100%;
}


.log {
    display:none;
    z-index:9;
    font-family:Courier;
    position:absolute;
    bottom: 0;
    height: 200px;
    width: 100%;
    padding:10px;
    font-size:10px;
    background: whitesmoke;
    color:rgba(0,0,0,0.85)
}
.log.fullscreen {
    bottom: unset;
    top: 0;
    height: 100%;
}





